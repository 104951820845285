/* footer {
    position: fixed;
    bottom: 0;
} */

/* Container {
    background-color: black;
} */

.body-text {
    text-align: center;
    margin: 0 5%;
}

p, li, h2{
    text-align: left;
    /* hyphens: auto; */
    word-wrap: break-word;
}

.body-section {
    padding-bottom: 1em;
}

.body-images {
    width: 100%;
    border: black solid;
    border-radius: 10px;
    @media (min-width: 750px) {
        display: inline;
        width: 50%;
        max-width: 300px;
        cursor: pointer;
    }

}